
import { defineComponent } from "vue-demi";
import Promocode from "@/components/promocode/List.vue";

export default defineComponent({
  name: "PromocodeView",
  components: {
    Promocode,
  },
});
